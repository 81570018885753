var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "30%", disabled: _vm.disabled },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5" }, [
            _vm._v("Are you sure you want to delete?"),
          ]),
          _c("v-card-text", [
            _vm._v(
              "Embedded videos will stop working after you delete the " +
                _vm._s(_vm.videoCopy()) +
                "!"
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vimeo-upload-dialog",
                {
                  attrs: { videos: _vm.videos, crud: "delete" },
                  on: { dismissed: _vm.dismiss },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "error" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Delete " + _vm._s(_vm.videoCopy()))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }